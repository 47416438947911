import imageUrlBuilder from '@sanity/image-url'

import type {
  SanityImage,
  SanityImageOptions,
  ResponsiveImage,
} from '~/types/groq'

import sanityConfig from '~/global/sanity'
import { isProd } from '~/global'

export type Image = string | ResponsiveImage | SanityImage

export const sizes = [300, 600, 900, 1200, 1920]

export function getImageUrl(
  image: SanityImage,
  {
    width,
    height,
    maxWidth,
    maxHeight,
    aspectRatio,
    quality = 82,
    fit = 'max',
    auto = 'format',
    crop,
  }: SanityImageOptions
) {
  if (!image) {
    if (isProd) console.info('Could not find image', image)
    return ''
  }
  const builder = imageUrlBuilder(sanityConfig)
  if (!builder)
    throw new Error(
      'You must call useSanityClient before using sanity resources in this project.'
    )

  let result = builder.image(image)
  if (width) result = result.width(width)
  if (width && aspectRatio) result = result.height(width / aspectRatio)
  if (height) result = result.height(height)
  if (maxWidth) result = result.maxHeight(maxWidth)
  if (maxHeight) result = result.maxHeight(maxHeight)
  if (crop) result = result.crop(crop)
  if (quality < 100) result = result.quality(quality)
  return result.fit(fit).auto(auto).url() || ''
}

export function isDefinedImage(
  image: ResponsiveImage | SanityImage
): image is ResponsiveImage {
  return (image as ResponsiveImage).src !== undefined
}

export const getSizes = (image, options): string =>
  [
    ...sizes.map(
      (width) => `${getImageUrl(image, { width, ...options })} ${width}w`
    ),
  ].join(', ')

type PreloadImage = {
  rel: 'preload'
  as: 'image'
  href: string
  imagesrcset: string
}

export const preloadImage = (
  image: SanityImage,
  options = {}
): PreloadImage => ({
  rel: 'preload',
  as: 'image',
  href: getImageUrl(image, options),
  imagesrcset: getSizes(image, options),
})

export function getSanityImage(
  image: SanityImage,
  options: SanityImageOptions = {}
) {
  if (!image || (!image.asset && !image.url)) return { src: '', srcset: '' }

  return {
    src: getImageUrl(image, options),
    srcset: !options.disableSrcset ? getSizes(image, options) : '',
    placeholder: image.metadata?.lqip,
  }
}
