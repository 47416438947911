import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  }
]