import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAtomsBlockquote, LazyAtomsBorder, LazyAtomsButton, LazyAtomsCaption, LazyAtomsDate, LazyAtomsDatedList, LazyAtomsEntry, LazyAtomsHamburger, LazyAtomsHeading, LazyAtomsIcon, LazyAtomsImage, LazyAtomsLargeText, LazyAtomsListComponent, LazyAtomsListItem, LazyAtomsList, LazyAtomsLoader, LazyAtomsNormal, LazyAtomsScrollDetector, LazyAtomsStats, LazyAtomsTags, LazyAtomsTimelineBorder, LazyBlocksSpotlight, LazyBlocksBackground, LazyBlocksCard, LazyBlocksContent, LazyBlocksEmbed, LazyBlocksEntry, LazyBlocksImages, LazyBlocksItem, LazyBlocksMap, LazyBlocksPagination, LazyBlocksSection, LazyBlocksTeam, LazyBlocksText, LazyBlocksTimeline, LazyBlocksVideo, LazyFormBody, LazyFormFieldAmount, LazyFormFieldCheck, LazyFormFieldGiftaid, LazyFormField, LazyFormFieldOptions, LazyFormFieldStripe, LazyPageHeader, LazySiteFooter, LazySiteHeader, LazySiteLightbox, LazySiteModal, LazySiteNav, LazyUnoIcon, LazySanityContent, LazySanityFile, LazySanityImage } from '#components'
const lazyGlobalComponents = [
  ["AtomsBlockquote", LazyAtomsBlockquote],
["AtomsBorder", LazyAtomsBorder],
["AtomsButton", LazyAtomsButton],
["AtomsCaption", LazyAtomsCaption],
["AtomsDate", LazyAtomsDate],
["AtomsDatedList", LazyAtomsDatedList],
["AtomsEntry", LazyAtomsEntry],
["AtomsHamburger", LazyAtomsHamburger],
["AtomsHeading", LazyAtomsHeading],
["AtomsIcon", LazyAtomsIcon],
["AtomsImage", LazyAtomsImage],
["AtomsLargeText", LazyAtomsLargeText],
["AtomsListComponent", LazyAtomsListComponent],
["AtomsListItem", LazyAtomsListItem],
["AtomsList", LazyAtomsList],
["AtomsLoader", LazyAtomsLoader],
["AtomsNormal", LazyAtomsNormal],
["AtomsScrollDetector", LazyAtomsScrollDetector],
["AtomsStats", LazyAtomsStats],
["AtomsTags", LazyAtomsTags],
["AtomsTimelineBorder", LazyAtomsTimelineBorder],
["BlocksSpotlight", LazyBlocksSpotlight],
["BlocksBackground", LazyBlocksBackground],
["BlocksCard", LazyBlocksCard],
["BlocksContent", LazyBlocksContent],
["BlocksEmbed", LazyBlocksEmbed],
["BlocksEntry", LazyBlocksEntry],
["BlocksImages", LazyBlocksImages],
["BlocksItem", LazyBlocksItem],
["BlocksMap", LazyBlocksMap],
["BlocksPagination", LazyBlocksPagination],
["BlocksSection", LazyBlocksSection],
["BlocksTeam", LazyBlocksTeam],
["BlocksText", LazyBlocksText],
["BlocksTimeline", LazyBlocksTimeline],
["BlocksVideo", LazyBlocksVideo],
["FormBody", LazyFormBody],
["FormFieldAmount", LazyFormFieldAmount],
["FormFieldCheck", LazyFormFieldCheck],
["FormFieldGiftaid", LazyFormFieldGiftaid],
["FormField", LazyFormField],
["FormFieldOptions", LazyFormFieldOptions],
["FormFieldStripe", LazyFormFieldStripe],
["PageHeader", LazyPageHeader],
["SiteFooter", LazySiteFooter],
["SiteHeader", LazySiteHeader],
["SiteLightbox", LazySiteLightbox],
["SiteModal", LazySiteModal],
["SiteNav", LazySiteNav],
["UnoIcon", LazyUnoIcon],
["SanityContent", LazySanityContent],
["SanityFile", LazySanityFile],
["SanityImage", LazySanityImage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
