export const internals = (title = true, fields?: string) => `
  ...(internal->{
    ${title ? `"text": title,` : ''}
    ${fields || ''}
    "to": {
      "id": _type + '_' + slug.current,
      "hash": ^.hash,
      "query": ^.query
    },
  })`

export const externals = `
  defined(url) => {
    "to": url,
  },
  defined(href) => {
    "to": href,
    "href": null
  }`
export const button = (fields: string) => `{
  type == 'internal' => {
    ${internals()}
  },
  type == 'external' => {
    ${externals},
  },
  defined(title) => { "text": title },
  type == 'latest-article' => {
    "to": { "id": 'article_' + *[_type == 'article' && dateTime(now()) > dateTime(datePublished)] | order(datePublished desc)[0].slug.current },
    "text": select(defined(title) => title, 'Latest article'),
  },
  ${fields}
}`

export const markDefs = `markDefs[]{
  ...,
  _type == "link" => {
    ${internals(false)},
    ${externals},
    "contentButton": "link",
    "internal": null,
  },
}`

export const simpleText = `{
  ...,
  ${markDefs},
  _type == 'button' => ${button('"contentButton": "button"')},
}`

export const image = `{
  "id": asset._ref,
  asset,
  alt,
  caption,
  showFullImage,
  ...(asset->{
    'sketch' in tags => {
      "class": "sketch",
      "zoom": false,
    },
    "metadata": {
      extension,
      tags,
      "sketch": 'sketch' in tags,
      "palette": metadata.palette,
      "dimensions": metadata.dimensions {
        width,
        height,
        aspectRatio,
        "orientation": select(
          aspectRatio > 1 => "landscape",
          aspectRatio < 1 => "portrait",
          "square"
        ),
        aspectRatio > 2 => { "panorama": true }
      },
      "isOpaque": metadata.isOpaque,
      "lqip": metadata.lqip
    },
  }),
  crop,
  hotspot,
  attributes,
}`