export default function useSerializers(type = 'default') {
  const button = resolveComponent('LazyAtomsButton')
  const images = resolveComponent('LazyBlocksImages')
  const heading = resolveComponent('LazyAtomsHeading')
  const normal = resolveComponent('LazyAtomsNormal')
  const large = resolveComponent('LazyAtomsLargeText')
  const blockquote = resolveComponent('LazyAtomsBlockquote')
  const caption = resolveComponent('LazyAtomsCaption')
  const dated = resolveComponent('LazyAtomsDatedList')
  const background = resolveComponent('LazyBlocksBackground')
  const map = resolveComponent('LazyBlocksMap')
  const card = resolveComponent('LazyBlocksCard')
  const textBlock = resolveComponent('LazyBlocksText')
  const spotlight = resolveComponent('LazyBlocksSpotlight')
  const video = resolveComponent('LazyBlocksVideo')
  const embed = resolveComponent('LazyBlocksEmbed')
  const form = resolveComponent('LazyFormBody')
  const timeline = resolveComponent('LazyBlocksTimeline')
  const stats = resolveComponent('LazyAtomsStats')
  const personnel = resolveComponent('LazyBlocksTeam')
  const content = resolveComponent('LazyBlocksContent')
  const entry = resolveComponent('LazyAtomsEntry')
  const list = resolveComponent('AtomsListComponent')
  const listItem = resolveComponent('LazyAtomsListItem')

  const marks = {
    em: 'em',
    strong: 'strong',
    link: button,
  }

  const styles = {
    heading,
    normal,
    large,
    blockquote,
    caption,
    dated,
  }

  const types = {
    heading,
    background,
    map,
    button,
    images,
    card,
    textBlock,
    spotlight,
    video,
    embed,
    form,
    timeline,
    stats,
    personnel,
    content,
    entry,
    list,
  }

  const serializers = {
    simpleText: {
      marks,
      types: {
        heading,
        button,
        images,
        entry,
      },
      styles,
    },
    default: {
      marks,
      types,
      styles,
      listItem,
    },
  }

  return serializers[type]
}
