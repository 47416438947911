import type { RouterOptions } from '@nuxt/schema'

import { isProd } from '../global'
async function scrollBehavior(to, from, savedPosition) {
  if (!isProd) console.log('scrollBehaviour', to)
  if (savedPosition) {
    if (!isProd) console.log('savedPosition', savedPosition)
    return savedPosition
  }

  if (process.server) {
    if (!isProd) console.log('scrollBehaviour on server')
    return 
  }

  const nuxtApp = useNuxtApp()
  console.log('hello')
  return new Promise(async (resolve) => {
    const main = document.documentElement || window

    const findEl = async (hash, x = 0) => {
      if (!isProd) console.log('router findEl', document.querySelector(hash))
      return (
        document.querySelector(hash) ||
        new Promise<void>((resolve) => {
          if (x > 50) return resolve()

          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
      )
    }

    if (!isProd)
      console.log('router rect', document.querySelector('#key-documents'))

    if (to.path === from.path && !to.hash) {
      if (!isProd) console.log('to.path === from.path && !to.hash')
      return
    }

    let top = 0
    if (!isProd) console.log(to.hash)
    if (to.hash) {
      const el = await findEl(to.hash)
      if (!el) return main.scrollTo(0, 0)
      if (el.getBoundingClientRect()?.top)
        top =
          el.getBoundingClientRect().top -
          document.documentElement.getBoundingClientRect().top
      else top = el.offsetTop
      top = top - 120
    }
    if (!isProd) console.log('top', top)

    if ('scrollBehavior' in document.documentElement.style)
      resolve(
        main.scrollTo({
          top,
          behavior: 'smooth',
        })
      )
    else return resolve(main.scrollTo(0, top))
  //   nuxtApp.hooks.hookOnce('page:finish', async () => {
  //     const main = document.documentElement || window

  //     const findEl = async (hash, x = 0) => {
  //       if (!isProd) console.log('router findEl', document.querySelector(hash))
  //       return (
  //         document.querySelector(hash) ||
  //         new Promise<void>((resolve) => {
  //           if (x > 50) return resolve()

  //           setTimeout(() => {
  //             resolve(findEl(hash, ++x || 1))
  //           }, 100)
  //         })
  //       )
  //     }

  //     if (!isProd)
  //       console.log('router rect', document.querySelector('#key-documents'))

  //     if (to.path === from.path && !to.hash) {
  //       if (!isProd) console.log('to.path === from.path && !to.hash')
  //       return
  //     }

  //     let top = 0
  //     if (!isProd) console.log(to.hash)
  //     if (to.hash) {
  //       const el = await findEl(to.hash)
  //       if (!el) return main.scrollTo(0, 0)
  //       if (el.getBoundingClientRect()?.top)
  //         top =
  //           el.getBoundingClientRect().top -
  //           document.documentElement.getBoundingClientRect().top
  //       else top = el.offsetTop
  //       top = top - 120
  //     }
  //     if (!isProd) console.log('top', top)

  //     if ('scrollBehavior' in document.documentElement.style)
  //       resolve(
  //         main.scrollTo({
  //           top,
  //           behavior: 'smooth',
  //         })
  //       )
  //     else return resolve(main.scrollTo(0, top))
  //   })
  })
}

export default <RouterOptions> {
  trailingSlash: false,
  scrollBehavior,
}
