<template>
  <component
    :is="to ? NuxtLink : element"
    :to="destination()"
    :type="type === 'submit' ? 'submit' : 'button'"
    :target="ext ? '_blank' : null"
    :rel="ext ? rel : null"
    :download="download"
    :class="[
      appearance,
      icon && (iconOnly ? 'is-icon' : 'has-icon'),
      event && `plausible-event-name=${event}`,
      {
        button:
          !plain && contentButton != 'link' && !appearance.includes('basic'),
        [`content-${contentButton}`]: !!contentButton,
        square: type === 'square' || type === 'close',
      },
    ]"
    :style="{ animationDelay: delay + 's' }"
    :title="title"
    :aria-label="type || title || (iconOnly && text)"
    class="nuxt-button"
    @click="handleClick()"
  >
    <LazyAtomsIcon
      v-if="icon && icon.name"
      class="nuxt-button-icon"
      v-bind="icon"
      :class="{
        alone: iconOnly || !text,
        reverse: appearance.includes('reverse'),
      }"
    />
    <span v-if="!iconOnly && text" class="nuxt-button-text" v-text="text" />
    <span v-if="!plain" class="nuxt-button-hover">
      <LazyAtomsIcon
        v-if="
          icon &&
          icon.name &&
          (appearance && appearance.includes('outline') || contentButton === 'button')
        "
        class="nuxt-button-icon nuxt-button-icon-hover"
        v-bind="icon"
        :class="{
          alone: iconOnly || !text,
          reverse: appearance.includes('reverse'),
        }"
      />
      <span
        v-if="
          !iconOnly &&
          text &&
          (appearance.includes('outline') || contentButton === 'button')
        "
        class="nuxt-button-text nuxt-button-text-hover"
        v-text="text"
      />
    </span>
    <slot />
  </component>
</template>

<script setup lang="ts">
const {
  $routes,
  $config: { isProd },
} = useNuxtApp()

type Link = {
  id: string
  hash?: string
  query?: { _key: string }
}

type Icon = {
  name: string
  provider: string
}

const props = defineProps({
  to: {
    type: [Object as () => Link, String],
    default: undefined,
  },
  delay: { type: Number, default: 0 },
  scrollTo: { type: [String, Number], default: undefined },
  title: { type: String, default: undefined },
  text: { type: String, default: undefined },
  rel: { type: String, default: 'noopener' },
  type: { type: String, default: '' },
  element: { type: String, default: 'button' },
  plain: { type: Boolean, default: false },
  appearance: { type: Array, default: () => [] },
  event: String,
  icon: { type: Object as () => Icon, default: undefined },
  iconOnly: { type: Boolean, default: false },
  contentButton: { type: String, default: '' },
  action: { type: String, default: undefined },
  download: { type: String, default: undefined },
})

const NuxtLink = resolveComponent('NuxtLink')

const ext: boolean =
  !!props.to &&
  typeof props.to === 'string' &&
  /^(http|mailto|tel|data).*/.test(props.to)

const destination = () => {
  if (!props.to || typeof props.to === 'string') return props.to

  let path = '/'
  const hash = props.to?.hash ? `#${props.to?.hash}` : null
  let query: any = props.to?.query || null

  if (query && typeof query === 'string')
    query = Object.fromEntries(new URLSearchParams(query))

  if ($routes && props.to?.id) {
    if (!$routes[props.to.id] && !isProd)
      console.log(`Warn: cannot find route for: ${props.to.id}`)
    else path = $routes[props.to.id]
  }

  return hash || query
    ? {
        path,
        hash: hash?.toLowerCase(),
        query,
      }
    : path
}

function handleClick() {
  if (!props.scrollTo || !process.client) return
  if (props.scrollTo) return cznScroll(props.scrollTo)
}
</script>

<style lang="scss">
.content-button {
  animation: appearX 1s ease-out backwards paused;
}
.nuxt-button {
  display: inline-flex;
  align-items: center;

  font-weight: 700;
  font-size: 1em;

  transition: transition($transition, (transform, color, background));

  .loader {
    position: relative;

    margin: 0 auto;
  }

  // &.active {
  //   color: var(--accent);
  // }

  // &-icon {
  //   background: var(--un-icon);
  // }

  &-icon.icon {
    order: -1;

    &:not(.alone) {
      margin-right: 0.4em;
    }

    &.reverse {
      order: 1;

      &:not(.alone) {
        margin-left: 0.4em;
        margin-right: 0;
      }
    }
  }

  &.icon.text {
    .nuxt-button-icon {
      margin-right: 0.25em;
    }
  }

  &:disabled,
  &[disabled=''],
  &[disabled='true'] {
    opacity: 0.4 !important;
    pointer-events: none;
  }

  &.circle {
    $size: 2em;

    display: flex;
    align-content: center;
    justify-content: center;
    width: $size;
    height: $size;
    padding: 0;

    font-weight: 700;
    font-size: 1rem;

    border-radius: 50%;
    // background: var(--white);
    // background: linear-gradient(
    //   27deg,
    //   tint(var(--grey), 25%),
    //   tint(var(--grey)Light, 25%)
    // );

    &::before,
    &::after {
      display: none;
    }
  }
  // &:not(.button),
  // &.expand {
  //   color: var(--black);

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     color: var(--accent);
  //   }
  // }

  &.content-link {
    display: contents;
    white-space: pre-wrap;
    color: var(--em);
    font-weight: 500;
  }

  &.button {
    position: relative;
    align-self: center;
    margin: 0 auto;
    align-items: center;

    padding: 0.8rem 3rem;

    color: var(--accent-90);
    letter-spacing: 0.1px;

    background: none;
    color: var(--accent);

    // filter: drop-shadow(0 0.05em 0.5em rgba(var(--black), 0.4));

    transition: $ts;

    &:hover,
    &:focus,
    &:active {
      color: var(--secondary);
    }

    &.content-button {
      display: inline-flex;
      margin: $sm 0 $md 0 - $sm;
      align-self: unset;
    }

    &.outline,
    &.content-button {
      padding: $rg $lg + $rg $rg + $sm;

      font: {
        family: var(--font-primary);
        style: italic;
      }
      border: 2px solid currentColor;

      border-radius: 2em;
      overflow: hidden;

      .nuxt-button-hover {
        position: absolute;
        display: inline-flex;
        align-items: center;
        inset: $rg $lg + $rg $rg + $sm;
        color: var(--em-dark);
        opacity: 0;
        transform: scale(1.2) translateX(-0.2em);
        transition: transition($transition, (transform, opacity));
        transition-delay: 0.1s;
      }

      &::before {
        position: absolute;
        inset: auto 0 auto -2px;
        content: '';
        width: 80%;
        height: 0;
        padding-bottom: 100%;
        transform: scale(0);
        border-radius: 50%;
        background: var(--primary);
        transition: transition($transition, (transform, opacity));
        transition-delay: 0.15s;
        transform-origin: 0 50%;
      }
      &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
          color: var(--primary);

          .nuxt-button-hover,
          .nuxt-button-hover {
            transform: scale(0.8) translateX(0.2em);
            opacity: 0;
          }

          .nuxt-button-hover,
          .nuxt-button-hover {
            transform: none;
            opacity: 1;
          }

          &::before {
            transform: scale(2);
          }
        }
        &:active {
          transform: scale(0.98);
        }
      }
    }

    // &::after {
    //   position: absolute;
    //   display: block;
    //   border: 2px solid;
    //   content: '';
    //   z-index: 0;
    // }

    // &::before {
    //   position: absolute;
    //   inset: 0;
    //   display: block;
    //   background: var(--accent);
    //   content: '';
    //   z-index: -1;
    //   transition: transition($transition, (transform));
    // }

    font: {
      weight: 600;
    }

    &:disabled {
      color: var(--accent) !important;

      background: rgba(var(--background), 0.2) !important;
      transform: none !important;
      cursor: not-allowed;
      // filter: drop-shadow(0 0.1em 0.1em rgba(var(--black), 0.4));
    }
    // &.white {
    // color: var(--background);

    // background: var(--accent);

    // &:hover,
    // &:focus,
    // &:active {
    //   color: var(--accent);

    //   background: var(--white);
    // }
    // }
  }

  &.has-icon {
    svg {
      margin-right: 0.4rem;

      color: currentColor;
      fill: currentColor;
    }
    .icon {
      color: currentColor;
      fill: currentColor;
    }
  }

  &.is-icon {
    padding: $md;
    font-size: 1.4em;

    &.outline {
      padding: $rg $md + $sm;

      .nuxt-button-hover {
        inset: $rg $md + $sm;
      }
    }

    &.square {
      padding: 0;
      font-size: 1.6rem;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 2em;
      height: 2em;

      .nuxt-button-hover {
        inset: auto;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--secondary);
  }
}
</style>
