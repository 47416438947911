<template>
  <div class="canvas">
    <!-- <VitePwaManifest /> -->
    <SiteHeader :social="social" />
    <LazySiteNav  :social="social" />
    <SiteModal  />
    <LazySiteLightbox />
    <NuxtPage />
  </div>
</template>

<script lang="ts" setup>
import { site } from './global';
import type { NavItem } from '~/types'

useSeoMeta({
  title: site.title,
  ogTitle: site.title,
  description: site.description,
  ogDescription: site.description,
  ogSiteName: site.title,
  // ogImage: '',
  twitterTitle: site.title,
  twitterDescription: site.description,
  twitterCard: 'summary_large_image',
  ogUrl: site.url,
})

const { button } = useGroq

const { data: social } = useSanityQuery<NavItem[]>(
  groq`*[_type == "siteSettings"][0].social[] {
    icon,
    ${button()},
    title,
    text
  }`
)
// import type { ModalContent } from '~/types/groq'

// const { modal } = useModal()

// const { button, image } = useGroq
// const { supportsLocalStorage } = useLocalStorage()

// // onMounted(() => {
// //   if (
// //     process.client &&
// //     supportsLocalStorage.value &&
// //     localStorage.getItem('modalClosed') === 'true'
// //   )
// //     modal.active = false
// // })

// const { data: modalContent } = useSanityQuery<ModalContent>(
//   groq`*[_type == "siteSettings"][0].modal {
//     enabled,
//     ${button(true, 'description,\n"image": images[0]' + image + ',')},
//     defined(description) => {description},
//     defined(image) => {image${image}},
//   }`,
//   {},
//   {
//     transform: (
//       data: Omit<ModalContent, 'title'> & { text: string }
//     ): ModalContent => {
//       try {
//         const title = data?.text
//         if (data?.enabled && !!title) modal.active = true

//         return {
//           ...data,
//           text: undefined,
//           title,
//         }
//       } catch (e) {
//         console.log(e)
//       }
//     },
//   }
// )
</script>
