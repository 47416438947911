<template>
  <img
    ref="observer"
    class="image"
    :class="[
      image.class,
      ...(image && image.metadata
        ? [image.metadata.dimensions.orientation, image.metadata.extension]
        : []),
      { loaded, intersected, zoom, contain: image && image.showFullImage },
    ]"
    :alt="alt || image.alt || image.title || ''"
    v-bind="{
      srcset,
      src,
      sizes,
    }"
    @load="load()"
    @click="handleClick()"
  />
  <!-- ...(image && image.attributes), -->
</template>

<script lang="ts" setup>
import type { SanityImage, ResponsiveImage } from '~/content/types'

const { isProd } = useNuxtApp()
const { lightbox } = useShared()

const props = defineProps({
  image: Object as () => SanityImage,
  options: Object,
  alt: String,
  sizes: String,
  io: { type: Object, default: () => ({}) },
  zoom: {
    type: Boolean,
    default: true,
  },
})

const loaded = ref(false)

const { observer, intersected } = useIntersectionObserver({ triggerOnce: true })

const imgObject = computed<ResponsiveImage>(() => {
  if (isDefinedImage(props.image))
    return Object.assign({ src, srcset }, props.image)

  return getSanityImage(props.image, props.options)
})

const src = computed(() =>
  intersected.value || props.io.intersected
    ? imgObject.value?.src
    : imgObject.value?.placeholder
)
const srcset = computed(() =>
  intersected.value || props.io.intersected ? imgObject.value.srcset : undefined
)

const emit = defineEmits(['load', 'zoom'])

function load() {
  if (!observer.value) throw new Error('Error loading image.')

  if (
    !imgObject.value.placeholder &&
    typeof props.image !== 'string' &&
    'id' in props.image &&
    !isProd
  )
    console.log('Missing lqip: ' + props.alt)

  if (
    typeof imgObject.value === 'string' ||
    (observer.value &&
      observer.value.getAttribute('src') !== imgObject.value?.placeholder)
  ) {
    loaded.value = true
    emit('load')
  }
}

function handleClick() {
  if (!props.zoom) return
  lightbox.set(props.image)
  // console.log(lightbox)
  emit('zoom')
}

// const img = getSanityImage(props.image, props.options).value
</script>

<style lang="scss">
.image.loaded {
  animation-play-state: running !important;
}

.image {
  width: 100%;
  object-fit: cover;
  transition: transition($transition, (transform, filter));

  &.sketch:not(.image-background-image) {
    object-fit: contain;

    @supports not (mix-blend-mode: multiply) {
      display: none;
    }
    mix-blend-mode: multiply;
  }

  &:not(.loaded) {
    filter: blur(3px);
  }

  &.landscape {
    width: 100%;
    height: auto;
  }

  &.portrait {
    height: 36rem;
    width: auto;
    min-width: 18rem;
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;

    &.portrait {
      min-width: unset;
    }
  }

  &.zoom {
    cursor: zoom-in;

    &:not(.lightbox-image) {
      &:hover,
      &:focus {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(1.02);
      }
    }
  }
}
</style>
