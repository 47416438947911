import { aliases } from '../global/icons'

export default defineNuxtPlugin(() => {
  const getIcon = (provider: string, name: string) => {
    const p = provider === 'hi' ? 'heroicons-solid' : provider

    const n = aliases[name] || name

    return `i-${p}-${n}`
  }

  return {
    provide: {
      getIcon,
    },
  }
})
