import type { ChildGroq, ItemGroq, PageGroq } from '~/types/groq'
import type { Page, Column, Section, Item, Card, Member } from '~/types'
import { layouts, site } from '~/global'

const theme = ref('')

const cols = ['first', 'second', 'third', 'sidebar'] as const
const bodyItems = ['block', 'button', 'heading', 'background', 'form', 'images']

const mask =
  'https://cdn.sanity.io/images/2oiwztas/production/4054d5931b9b262e69b743712bdb9c621a37a887-16355x600.png?auto=format'

export default () => {
  const { $glossary } = useNuxtApp()

  // const contains = (arr, str) =>
  //   arr.some((element) => {
  //     if (str.includes(element)) {
  //       console.log(element)
  //       return true
  //     } else return false
  //   })

  const addArticleButton = (type: string): Section | undefined => {
    if (!['article', 'story'].includes(type)) return
    const types = type === 'story' ? 'Stories' : 'News'
    return {
      _key: 'articleButton',
      layout: 'col-1',
      options: {},
      theme: {},
      columns: [
        {
          name: 'first',
          stretch: false,
          delay: 0,
          items: [
            {
              key: '0',
              delay: 0,
              style: 'margin-top: 2rem',
              _type: 'button',
              text: `Back to ${types}`,
              contentButton: 'button',
              to: { id: `page_${types.toLowerCase()}` },
            },
          ],
        },
      ],
    }
  }

  const generateCards = (
    slug: string,
    relatedPages: Card[],
    hidePageCards?: boolean
  ): Section | undefined => {
    let items = relatedPages?.filter((page) => page.slug !== slug)

    if (items?.length > 3) items = items?.filter((page) => !page.parent)

    if (hidePageCards || !items?.length) return

    const layout =
      items.length === 1
        ? `col-1`
        : items.length === 2
        ? `col-2-equal`
        : 'col-3-equal'

    const bodyCards: Section = {
      _key: 'bodyCards0',
      layout,
      options: {},
      theme: {},
      columns: ['first', 'second', 'third']
        .map(
          (col, index) =>
            items[index] && {
              name: col,
              delay: index * 0.5,
              items: [
                {
                  _key: index,
                  delay: index * 0.5,
                  _type: 'card',
                  ...items[index],
                },
              ],
              stretch: true,
            }
        )
        .filter((col) => !!col),
    }

    if (!bodyCards.columns.length) {
      console.log(bodyCards)
      return
    }

    return bodyCards
  }

  const termsUsed: string[] = []

  const parseChildren = (children: ChildGroq[]) => {
    const newChildren: ChildGroq[] = []

    children?.slice()?.forEach((child) => {
      if (child.marks?.length && !child.marks.includes('strong'))
        return newChildren.push(child)
      const matches: { term: string; index: number }[] = []
      $glossary.terms.forEach((term) => {
        if (termsUsed.includes(term)) return
        const index = child.text.indexOf(term)
        if (index === -1) return
        matches.push({ term, index })
        // termsUnused = termsUnused.filter(
        //   (t) => !$glossary.entries[term]?.terms?.includes(t)
        // )
        termsUsed.push(...($glossary.entries[term]?.terms || [term]))
      })

      if (!matches?.length) return newChildren.push(child)


      const finalChild: ChildGroq[] = []

      const sortedMatches = matches.sort((a, b) => a.index - b.index)

      sortedMatches.forEach(({ term }) => {
        const useText = finalChild.pop()?.text || child.text
        const [first, ...rest] = useText.split(term)

        finalChild.push(
          ...[
            {
              ...child,
              text: first,
            },
            {
              ...child,
              ...$glossary.entries[term],
              _type: 'entry',
              text: term,
            },
            {
              ...child,
              text: rest.join(term),
            },
          ]
        )
      })

      return newChildren.push(...finalChild)
    })

    return newChildren
  }

  const parseItem = (item: ItemGroq) => {
    if (item._type !== 'block' || item.element || !item.children) return item

    return {
      ...item,
      children: parseChildren(item.children),
    }
  }

  const itemParser = (item: ItemGroq) => {
    const subs = ['personnel', 'timeline']
    if (subs.includes(item._type)) {
      // console.log(item)
      let sub = ''
      let subItem = ''
      switch (item._type) {
        case 'personnel':
          sub = 'team'
          subItem = 'biography'
          break
        case 'timeline':
          sub = 'chapters'
          subItem = 'content'
          break
      }
      return {
        ...item,
        [sub]: item[sub]?.map((child: Member, index) => ({
          ...child,
          [subItem]: child[subItem]?.map((subSub, itemIndex) => ({
            ...parseItem(subSub),
            delay: index * 0.25 + itemIndex * 0.25,
          })),
        })),
      }
    }

    return parseItem(item)
  }

  const transform = (page: PageGroq): Page => {
    let ind = 0
    let rev = true
    let cards = false
    const pageTheme = page.options.altLayout ? 'alt' : undefined

    const body = page.body.map((section, thisIndex): Section => {
      const columns = cols
        .filter(
          (col) =>
            section[col]?.items?.length &&
            // @ts-ignore
            layouts[section.layout]?.includes(col)
        )
        .map(
          (col, index): Column => ({
            name: col,
            // background: section[col].background,
            // ...section[col],
            delay: index * 0.5,
            items:
              section[col]?.items.map(
                (item, itemIndex): Item => ({
                  ...itemParser(item),
                  caption:
                    (item.style === 'blockquote' &&
                      section[col]?.items[itemIndex + 1]?.style ===
                        'caption') ||
                    null,
                  delay: index * 0.5 + itemIndex * 0.3,
                })
              ) || [],
            stretch: !section[col]?.items?.every((item) =>
              bodyItems.includes(item._type)
            ),
          })
        )
      const sectionCards = columns.every(
        (col) => col?.items[0]?._type === 'card'
      )
      const sectionSdImage = section.sidebar?.items[0]._type === 'images'
      let reverse = false
      if (section.layout === 'col-2') {
        if (ind === thisIndex && (sectionSdImage || (cards && sectionCards))) {
          reverse = rev
          rev = !reverse
        }
        ind = thisIndex + 1
      } else rev = true

      cards = sectionCards

      const image = section.background?.image

      const theme =
        pageTheme === 'alt' && section.background
          ? {
              // layout: section.background.layout,
              background: image?.metadata.palette.darkMuted.background, // section.background.color ||
              image,
              color: image?.metadata.palette.darkMuted.title,
              accent: image?.metadata.palette.darkMuted.foreground,
            }
          : {}

      return {
        _key: section._key,
        layout: section.layout,
        options: section.options,
        level: section.level,
        align: section.align,
        theme,
        map:
          section.layout === 'col-1' &&
          columns[0]?.items[0]?._type === 'map' &&
          columns[0]?.items.length === 1,
        columns,
        reverse,
        sectionCards,
        sectionSdImage,
      }
    })


    const relatedPages: Card[] =
      (page.children && page.children.filter((child) => !child.parent)?.length
        ? page.children
        : page.siblings) || []

    delete page.children
    delete page.siblings

    theme.value = page.options.altLayout ? 'alt' : ''

    const articleButton = addArticleButton(page.type)
    if (articleButton) {
      const lastBody = body[body.length - 1]
      if (lastBody)
        lastBody.options.hideMarginBottom = true

      body.push(articleButton)
    }

    const bodyCards = generateCards(
      page.slug,
      relatedPages,
      page.options.hidePageCards
    )
    if (bodyCards) body.push(bodyCards)

    const title = page.title === 'Home' ? site.title : `${page.title} ~ ${site.title}`
    const image = page.image ? getImageUrl(page.image, {
      width: 1200,
      quality: 80,
    }) : undefined

    return reactive({
      ...page,
      heading: {
        ...page.heading,
        nav:
          page.type !== 'page' && page.heading.parent
            ? [page.heading.parent]
            : relatedPages?.map((item) => ({
                ...item,
                text: item?.title, // item?.text || 
                title: undefined,
              })),
      },
      theme: pageTheme,
      body,
      head: {
        title,
        description: page.description,
        meta: [
          {
            hid: 'og:title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'twitter:title',
            property: 'twitter:title',
            content: title,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: page.description,
          },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: page.description,
          },
          ...(!!image
            ? [
                {
                  hid: 'og:image',
                  property: 'og:image',
                  content: image
                },
                {
                  hid: 'twitter:image',
                  property: 'twitter:image',
                  content: image
                },
              ]
            : []),
        ],
        link: [
          ...(page.heading?.background?.image && !page.options?.altLayout
            ? [
                {
                  rel: 'preload',
                  as: 'image',
                  href: mask,
                  media: '(min-width: 1024px)',
                  crossOrigin: 'anonymous',
                } as any,
              ]
            : []),
          ...(page.heading?.background?.image
            ? [preloadImage(page.heading.background.image)]
            : []),
          ...(page.image ? [preloadImage(page.image)] : []),
        ],
      }
    })
  }

  return {
    transform,
    theme,
  }
}
