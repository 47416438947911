import revive_payload_client_IeA0VOgvo2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wBwxmmgag5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FSnr03mHB5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iGYWBWSOpA from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_bsoF3EVLMl from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_szXp4rl6id from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import global_helper_Nw6vy8vga7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+sanity@1.11.2/node_modules/@nuxtjs/sanity/dist/runtime/plugins/global-helper.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import chunk_reload_client_JFivfhz3iF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_vxukuomdd26atvomr67pxxhrza/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import icons_sncVX745v6 from "/vercel/path0/plugins/icons.ts";
import sanity_iFCuiLdTZU from "/vercel/path0/plugins/sanity.ts";
export default [
  revive_payload_client_IeA0VOgvo2,
  unhead_wBwxmmgag5,
  router_FSnr03mHB5,
  payload_client_iGYWBWSOpA,
  check_outdated_build_client_bsoF3EVLMl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_szXp4rl6id,
  global_helper_Nw6vy8vga7,
  unocss_MzCDxu9LMj,
  chunk_reload_client_JFivfhz3iF,
  icons_sncVX745v6,
  sanity_iFCuiLdTZU
]