import type { SanityImage } from '~/types/groq'

const mounted = ref(false)

const affix = reactive({
  top: ref(false),
  attendees: ref(false),
  bottom: ref(false),
})

const menu = ref(false)

const lightbox = reactive({
  value: ref<false | SanityImage>(false),
  active: ref(false),
  current: ref<number>(0),
  zoom: ref(false),
  opened: ref(false),
  images: ref<SanityImage[]>([]),
  set(item: false | SanityImage) {
    if (!lightbox.opened) lightbox.opened = true
    lightbox.active = true
    lightbox.zoom = false
    lightbox.value = item
  },
  close() {
    lightbox.active = false
    lightbox.zoom = false
    lightbox.value = false
    lightbox.images = []
  },
  toggleZoom() {
    lightbox.zoom = !lightbox.zoom
  },
  setImages(index: number, images: SanityImage[]) {
    lightbox.images = images
    lightbox.current = index
  },
  prev() {
    if (!lightbox.images.length) return
    lightbox.current = (lightbox.current - 1) % lightbox.images.length
    lightbox.value = lightbox.images[lightbox.current]
  },
  next() {
    if (!lightbox.images.length) return
    lightbox.current = (lightbox.current + 1) % lightbox.images.length
    lightbox.value = lightbox.images[lightbox.current]
  },
})

export default () => ({
  mounted,
  affix,
  menu,
  lightbox,
})
