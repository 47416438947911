<template>
  <div class="canvas">
    <ClientOnly>
      <SiteHeader />
      <LazySiteNav />
      <main class="page">
        <BlocksSection>
          <div class="section-column error-column">
            <p>The page you are looking for could not be found.</p>
            <AtomsButton
              content-button="button"
              text="Return to home"
              @click="handleError()"
            />
          </div>
        </BlocksSection>
        <SiteFooter />
      </main>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
defineProps({
  error: Object,
})

const handleError = () => clearError({ redirect: '/' })
</script>

<style lang="scss">
.page {
  .error-column {
    margin-top: 20rem;
  }
}
</style>
