import type { Ref } from 'vue'
import { isProd } from './../global'

export const useLocalStorage = () => {
  const supportsLocalStorage = ref(true)

  function testStorage() {
    try {
      const test = '__check-localStorage'
      window.localStorage.setItem(test, test)
      window.localStorage.removeItem(test)
    } catch (e) {
      console.info('Local storage is not supported, falling back to cookie use')
      supportsLocalStorage.value = false
    }
  }

  onMounted(() => {
    testStorage()
  })

  return {
    supportsLocalStorage
  }
}

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => func(...args), waitFor)
  }

  return debounced as (...args: Parameters<F>) => ReturnType<F>
}

export const throttle = <F extends (...args: any[]) => any>(
  func: F,
  timeout: number
) => {
  let ready = true
  return (...args) => {
    if (!ready) return

    ready = false
    func(...args)
    setTimeout(() => {
      ready = true
    }, timeout)
  }
}

export async function cznScroll(
  scrollTo: string | number | Ref<HTMLElement>
): Promise<any> {
  if (!process.client) {
    console.log('Called server side')
    return
  }

  const findEl = async (hash: string, x = 0): Promise<void | HTMLElement> => {
    return (
      (document.querySelector(hash) as HTMLElement) ||
      new Promise<void | HTMLElement>((resolve) => {
        if (x > 50) return resolve()
        setTimeout(() => {
          resolve(findEl(hash, ++x || 1))
        }, 100)
      })
    )
  }
  const main = document.documentElement || window
  let top = 0
  if (typeof scrollTo === 'number') top = scrollTo
  else {
    const el =
      typeof scrollTo === 'string'
        ? await findEl(`#${scrollTo}`)
        : scrollTo.value
    if (!el) return main.scrollTo(0, 0)
    if (el.getBoundingClientRect().top)
      top =
        el.getBoundingClientRect().top -
        document.documentElement.getBoundingClientRect().top
    else top = el.offsetTop
  }
  top = top - 80
  if (!isProd) console.log('cznScroll', top)

  if ('scrollBehavior' in document.documentElement.style)
    return main.scrollTo({
      top,
      behavior: 'smooth',
    })
  else return main.scrollTo(0, top)
}

/**
 * Observe whether an element is in the viewport window
 * For performance, set `triggerOnce: true`
 * Assign an element reference (ref) to `observer`
 */
export function useIntersectionObserver(options?: {
  root?: any
  rootMargin?: string
  intersectionRatio?: number
  triggerOnce: boolean
}) {
  const observer = ref<HTMLElement | null>()
  const intersectionRatio = ref(0)
  const isIntersecting = ref(false)
  const intersected = ref(false)
  const isFullyInView = ref(false)

  function observe() {
    if (observer.value) intersectionObserver.observe(observer.value)
  }
  function unobserve() {
    if (!observer) return
    if (observer.value) intersectionObserver.unobserve(observer.value)
  }

  let intersectionObserver
  onMounted(function () {
    intersectionObserver = new IntersectionObserver(function (_a) {
      const entry = _a[0]
      intersectionRatio.value =
        options?.intersectionRatio || entry.intersectionRatio
      if (entry.intersectionRatio > 0) {
        isIntersecting.value = true
        intersected.value = true
        isFullyInView.value = entry.intersectionRatio >= 1

        if (options?.triggerOnce) unobserve()
        return
      }
      isIntersecting.value = false
    }, options)
    observe()
  })

  onUnmounted(unobserve)

  return {
    observer,
    intersectionRatio,
    isIntersecting,
    intersected,
    isFullyInView,
    observe,
    unobserve,
  }
}

export function useWindowScrollPosition(options = { throttleMs: 100 }) {
  const x = ref(0)
  const y = ref(0)
  function setScrollPos() {
    x.value = window.pageXOffset
    y.value = window.pageYOffset
  }
  const onScroll = throttle(setScrollPos, options.throttleMs)
  onBeforeMount(() => setScrollPos())
  onMounted(() =>
    window.addEventListener('scroll', onScroll, { passive: true })
  )
  onUnmounted(() => window.removeEventListener('scroll', onScroll))
  return { x, y }
}

const slugifyFull = (text) =>
  text
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

export const slugify = (text, options = { disableLowercase: false }) =>
  slugifyFull(
    options?.disableLowercase ? text.toString() : text.toString().toLowerCase()
  )

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validEmail = (email?: string) => email && emailRegex.test(email)

const phoneRegex =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/

export const validPhone = (number?: string) => number && phoneRegex.test(number)

export const formatDate = (date_string?: string): string => {
  const d = date_string ? new Date(date_string) : new Date()
  const weekday = new Array(7)
  weekday[0] = 'Sunday'
  weekday[1] = 'Monday'
  weekday[2] = 'Tuesday'
  weekday[3] = 'Wednesday'
  weekday[4] = 'Thursday'
  weekday[5] = 'Friday'
  weekday[6] = 'Saturday'
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    weekday[d.getDay()] +
    ' ' +
    d.getDate() +
    ' ' +
    monthNames[d.getMonth()] +
    ' ' +
    d.getFullYear()
  )
}

export const formatPrice = (price: string | number) => {
  if (typeof price === 'number') price = price.toFixed(2)
  if (!price) return ''
  return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const capitalise = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

export function handleEventListener<K extends keyof WindowEventMap>(
  watcher: any,
  type: K,
  eventListener: (e: WindowEventMap[K]) => void
) {
  if (!process.client) return
  if (watcher) window.addEventListener<K>(type, eventListener)
  else window.removeEventListener<K>(type, eventListener)
}
