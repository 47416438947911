<template>
  <BlocksSection class="site-footer theme-dark">
    <AtomsButton to="/" class="site-footer-logo"
      ><Logo /><span class="site-footer-logo-text"
        >Meeting the need</span
      ></AtomsButton
    >
    <AtomsList custom-class="site-footer-nav" :items="menu" />
    <div v-if="siteSettings" class="row">
      <div class="site-footer-small-print">
        <SanityContent
          :blocks="siteSettings.smallPrint"
          :serializers="useSerializers('simpleText')"
        />
      </div>
      <div class="site-footer-contact">
        <h4 class="site-footer-contact-header">
          <AtomsButton :to="{ id: 'page_contact' }" text="Contact us" plain />
        </h4>
        <AtomsList
          :items="siteSettings.social"
          custom-class="site-footer-social"
          icon-only
        />
      </div>
    </div>
  </BlocksSection>
</template>

<script lang="ts" setup>
import Logo from '~/assets/svg/logo.svg'

defineProps({
  nav: Array,
  contact: Array,
})

const { button } = useGroq

const query = groq`*[_type == "menu" && slug.current == $slug][0].items[] {
  ${button()}
}`
const { data: menu } = useSanityQuery(query, { slug: 'main' })

const socialQuery = groq`*[_type == "siteSettings"][0] {
  smallPrint[] {
    ...,
    _type == "button" => {
      ${button()},
      "contentButton": "link"
    },
  },
  social[] {
    ...,
    ${button()}
  }
}`
const { data: siteSettings } = useSanityQuery(socialQuery)
</script>

<style lang="scss">
.site-footer {
  position: relative;
  background: var(--bg);
  color: var(--body);
  width: 100vw;
  padding: 2rem 3vw $md;
  font-size: 1.2rem;
  clip-path: ellipse(70% 100% at 50% 100%);
  margin: 0 !important;

  @include media('<desktop') {
    padding: 2rem $md $md;
    clip-path: ellipse(150% 100% at 50% 100%);

    .site-footer-nav-list {
      @include media('<desktop') {
        flex-flow: column;
        align-items: center;
      }
    }

    .list-item-button {
      padding: $rg $md;
    }
  }

  &-logo {
    &-text {
      color: var(--em);
      margin-top: 0.5em;
    }
  }

  .site-footer-nav-list {
    flex-flow: row wrap;
    justify-content: center;
  }
  // &::before {
  //   position: absolute;
  //   width: 100vw;
  //   background: var(--em);
  //   inset: auto 0 100%;
  //   height: 10rem;
  //   content: '';
  // }

  .row {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include media('<desktop') {
      flex-flow: column;
      align-items: center;
    }
  }

  &-small-print {
    font-size: 1rem;
    white-space: pre;
    line-height: 1.4;

    @include media('<desktop') {
      margin-top: 1rem;
      order: 1;

      display: flex;
      flex-flow: row;
    }

    @include media('<=440px') {
      flex-flow: row wrap;
      justify-content: center;
      text-align: center;
    }

    .content-link {
      display: inline-flex;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    > * {
      animation-play-state: inherit !important;
    }
  }

  &-contact {
    display: flex;
    align-items: center;
    &-header {
      font-size: 1.6rem;
      white-space: nowrap;
      margin: $md;
      margin-right: $lg;
    }
  }

  &-nav-list {
  }

  .site-footer-logo {
    display: flex;
    flex-flow: column;
    font-family: var(--font-primary);
    font-style: italic;

    svg {
      width: 8rem;
    }

    path {
      fill: $white !important;
    }
  }

  > .container {
    display: flex;
    flex-flow: column;
  }
}
</style>
