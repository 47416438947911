import { image } from './groq'

const pagePath = `
  "path": '/' + slug.current,
  slug.current == 'home' => {
    "path": '/'
  },
  ...(parent->{
    ^.slug.current != 'home' && defined(slug.current) && slug.current != 'home' => {
      "path": '/'
        + slug.current + '/'
        + ^.slug.current,
      ...(parent->{
        defined(slug.current) && slug.current != 'home' => {
          "path": '/'
            + slug.current + '/'
            + ^.slug.current + '/'
            + ^.^.slug.current,
          ...(parent->{
            defined(slug.current) && slug.current != 'home' => {
                "path": '/'
                  + slug.current + '/'
                  + ^.slug.current + '/'
                  + ^.^.slug.current + '/'
                  + ^.^.^.slug.current,
            },
          })
        },
      })
    }
  })
`

const articleFields = `
  "datePublished": "2023",
  _type in ['article', 'story'] => {
    "type": _type,
    title,
    datePublished,
    description,
    quote,
    tags,
    "image": images[0]${image}
  },
`

const routes = (
  full = false,
  resources = true
) => `*[_type in ['page', 'article', 'story'${
  resources ? ", 'resource'" : ''
}] && defined(slug.current)] { 
  "id": _type + '_' + slug.current,
  _type == 'page' => {
    ${pagePath}
  },
  _type == 'article' => {
    "path": '/news/' + slug.current
  },
  _type == 'story' => {
    "path": '/stories/' + slug.current
  },
  ${full ? articleFields : ''}
  _type == 'resource' => {
    "path": file.asset->url
  }
}${full ? ' | order(datePublished desc)' : ''}`

export default routes
