<template>
  <component
    :is="element"
    ref="observer"
    class="section"
    :class="[
      `section-${layout}`,
      {
        'section-reverse': reverse,
        'section-level': level,
        'section-cards': sectionCards,
        'section-map': map,
        'section-sd-image': sectionSdImage,
        'section-hide-mb': options.hideMarginBottom,
        'section-has-bg':
          !!(theme.image || background.image) && layout !== 'col-2-image',
        intersected,
        'section-spotlight': options.spotlight,
      },
    ]"
  >
    <LazyBlocksBackground
      v-if="theme.image || theme.background || background.image"
      class="section-background"
      :image="theme.image || background.image"
      :intersected="element !== 'header' && intersected"
      :supermask="supermask"
    />
    <div
      class="container section-container"
      :style="{ color: theme.background }"
    >
      <slot />

      <div
        v-for="col in columns"
        :key="col.name"
        class="section-column column"
        :class="[col.name, { stretch: col.stretch, 'theme-dark': col.name === 'first' && options.spotlight  }]"
        :style="{ color: theme.color }"
      >
        <slot :name="col.name" />
        <SanityContent :blocks="col.items" :serializers="useSerializers()" />
        <!-- <component
            v-for="block in col.items" 
            :key="block._key"
            :is="serializers.types[block._type]"
            v-bind="block"
          /> -->

        <!-- <atoms-image
          v-if="props[col].background"E
          class="column-background"
          v-bind="props[col].background"
        /> -->
      </div>
    </div>
    <slot name="after" />
  </component>
</template>

<script lang="ts" setup>
import type { Section, Column } from '~/types'
import type { Layout } from '~/types/groq'

defineProps({
  _key: String,
  element: {
    type: String,
    default: 'section',
  },
  layout: {
    type: String as () => Layout,
    default: 'col-1',
  },
  level: Boolean,
  reverse: Boolean,
  // alignment: { type: String, default: 'left' },
  columns: Array as () => Column[],
  theme: {
    type: Object as () => Section['theme'],
    default: () => ({}),
  },
  background: {
    type: Object as () => Section['background'],
    default: () => ({}),
  },
  map: Boolean,
  sectionCards: Boolean,
  sectionSdImage: Boolean,
  supermask: Boolean,
  options: {
    type: Object,
    default: () => ({}),
  },
})
const { observer, intersected } = useIntersectionObserver({
  rootMargin: '0px 0px -10% 0px',

  // intersectionRatio: 0.2,
  triggerOnce: true,
})
</script>

<style lang="scss">
@mixin left {
  // .section-background {
  //   left: 0 - $xl;
  // }
  .container {
    grid-template-columns: 2fr 1fr;
  }
  .sidebar {
    order: 1;
  }
}
@mixin right {
  // .section-background {
  //   right: 0 - $xl;
  // }
  .container {
    grid-template-columns: 1fr 2fr;
  }
  .sidebar {
    order: -1;
  }
}
.section {
  position: relative;
  width: min(96vw, calc(100% - 2rem));
  margin-bottom: 14rem;
  text-align: left;

  &.section-hide-mb {
    margin-bottom: 1rem;

    .images:last-child {
      margin-bottom: 1rem;
    }
  }

  &.section-map .section-column {
    width: 100% !important;
  }

  &.section-spotlight {
    &.section-col-2 .container {
      grid-template-columns: 3fr 2fr;
      grid-gap: 8rem;
    }

    .container {
      $inset: 0 3rem;
      padding: $inset;

      border-radius: var(--br-items-xs);

      &::before {
        display: block;
        position: absolute;
        inset: $inset;

        background: rgba(rgb(48, 82, 48), 0.1);
        border-radius: inherit;

        transition: transition(var(--transition), (background));

      }
    }

    .column.first {
      background: var(--bg);
      padding: $lg $lg $md;

      border-radius: var(--br-items-xs);

      animation: appearX 1s ease-out both;
    }

    .column.sidebar {
      padding: $lg $lg $md 0;
    }
    &.section-reverse .column.sidebar {
      padding: $lg 0 $md $lg;
    }
    .column.second {
      padding: $lg $lg $md $xl;
    }


    &:not(.intersected) {
      .container::before {
        background: rgba(rgb(48, 82, 48), 0);
      }

      .column.first {
        animation-play-state: paused;
      }
    }

  }

  .section {
    &-container {
      position: relative;
      display: grid;
      grid-gap: $xl;
      justify-content: flex-start;

      margin: 0 auto;

      @include media('>=desktop') {
        justify-items: center;
      }

      // @include media('<tablet') {
      //   grid-gap: $md;
      //   flex-flow: column;
      // }

      @include media('<desktop') {
        display: flex;
        flex-flow: row wrap;
        // grid-template-columns: 100% !important;
        grid-gap: $md;
        align-content: flex-start;
        justify-content: center;
        // flex-flow: column;

        .section-column {
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
          &.stretch {
            width: fit-content;
          }
        }
      }
    }

    &-background {
      position: absolute;
      inset: 5vh -2rem;
      height: 80vh;
      width: 100vw;
      opacity: 0.2;

      object-fit: cover;

      .image-background-image {
        position: relative;
        inset: unset;
        width: auto;
        height: 100%;
        mix-blend-mode: multiply;

        @include media('>=desktop') {
          left: 14%;
        }
      }

      // &-wrapper {

      //   @supports not (mix-blend-mode: multiply) {
      //     display: none;
      //   }
      //   mix-blend-mode: multiply;
      // }

      &.sketch {
        object-fit: contain;

        &.portrait {
          object-position: 14% 0;
        }
      }
    }
    &-column {
      position: relative;
      align-items: flex-start;
      justify-self: center;

      @include media('<=tablet') {
        width: 100%;
        max-width: $container-max-width;
      }

      &.sidebar {
        min-width: 20rem;
      }

      &.stretch {
        width: 100%;
        max-width: $container-width;
        align-items: center;

        @include media('<=tablet') {
          max-width: $container-max-width;
        }
      }
    }
    // &-content {
    //   flex: 1 1;
    //   display: flex;
    //   flex-flow: column;
    //   justify-content: flex-start;

    //   padding: 0 1.5vw;
    //   margin-bottom: 10vh;

    //   &:first-child {
    //     padding-left: 0;
    //   }
    //   &:last-child {
    //     padding-right: 0;
    //   }
    // }
  }

  &-after {
    width: 100%;
    padding: 4rem 3vw;

    p {
      margin: auto;
    }
  }

  &-col {
    &-1,
    &-2-image {
      .container {
        grid-template-columns: 1fr;
      }
    }
    &-2 {
      // .container {
      //   grid-template-columns: 2fr 1fr;
      // }
      @include left;

      @include media('>=desktop') {
        &.section-reverse {
          @include right;

          &.section-cards .sidebar {
            margin-top: -10vh;
          }
        }
      }
    }
    &-2-image {
      @include media('<tablet') {
        min-height: unset !important;
      }
      @include media('>=tablet') {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .section-background.image-background {
          height: 100vh;
        }
      }

      .section-background.image-background {
        position: relative;
        grid-column: 1;
        width: 100%;
        @include media('<tablet') {
          height: auto;
        }
      }

      .section-container {
        align-items: center;
      }

      // + .section-col-2-image {
      //   .section-background.image-background {
      //     grid-column: 2;
      //   }
      // }
    }
    &-2-equal {
      .container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    &-2:not(.section-level) .sidebar,
    &-2-equal:not(.section-level) .second {
      @include media('>=desktop') {
        margin-top: 10vh;
      }
    }
    &-3-equal {
      .container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  // &-left {
  //   .section-background {
  //     left: 0 - $xl;
  //   }
  // }

  // &-right {
  //   .section-background {
  //     right: 0 - $xl;
  //   }

  //   &.section-col-2 {
  //     .container {
  //       grid-template-columns: 1fr 2fr;
  //     }
  //     .sidebar {
  //       order: -1
  //     }
  //     // .sidebar {
  //     //   grid-column: 1;
  //     // }
  //     // .first {
  //     //   grid-column: 2;
  //     // }
  //   }
  // }
}
</style>
